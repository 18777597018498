import { Injectable, Inject } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TRANSLATIONS, dictionary } from '../translations'; // import our opaque token
import { EnvService } from '../../services/env.service';

@Injectable()
export class TranslateService {

  private _currentLang: string;

  constructor(
    @Inject(TRANSLATIONS) private _translations: any,
    private platform: Platform,
    private env: EnvService,
  ) {
    this.platform.ready().then(() => {
      this.setBrowserLanguage();
    });
  }

  public get currentLang(): string {
    return localStorage.getItem('currentLang') || this._currentLang;
  }

  // Set Lingua
  public use(lang: string): void {
    // set current language
    this._currentLang = lang;
    localStorage.setItem('currentLang', lang);
  }

  private translate(key: string): string {
    // private perform translation
    const translation = key;

    if (this._translations[this.currentLang] && this._translations[this.currentLang][key]) {
      return this._translations[this.currentLang][key];
    } else if (this._translations.it[key]) {
      return this._translations.it[key];
    }

    return translation;
  }

  // Traduzione dal component.ts
  public instant(key: string): string {
    // call translation
    return this.translate(key);
  }

  private setBrowserLanguage(): void {
    const language = navigator.language;
    this.env.consoleLog(language);
    const lang = (language.indexOf('-') > -1) ? language.split('-')[0] : language;
    for (const key in dictionary) {
      if (key === lang) {
        this.use(key);
        return;
      }
    }
    this.use('it');
    return;
  }

}

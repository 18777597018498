import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AuthService } from '../services/auth.service';
/* PROVIDER CoredataService - Core Backend App Data */
import { CoredataService } from '../services/coredata.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private navCtrl: NavController,
    private storage: Storage,
    private authService: AuthService,
    private coredataService: CoredataService
  ) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // return of(true);
    console.log('ROUTE state ', state)
    console.log('ROUTE route ', route)
    await this.storage.ready();
    if (this.authService.user) {
      if (this.coredataService.isBrowser) {
        const currentRouteUrl = state.url.split('?')[0];
        console.log('AUTH GUARD currentRouteUrl', currentRouteUrl);
        if ((currentRouteUrl === '/tabs/buyer') || (currentRouteUrl === '/buyer')) {
          this.navCtrl.navigateRoot('/buyer/data');
          return false;
        } else if ((currentRouteUrl === '/tabs/seller') || (currentRouteUrl === '/seller')) {
          this.navCtrl.navigateRoot('/seller/data');
          return false;
        } else if ((currentRouteUrl === '/tabs/dealers') || (currentRouteUrl === '/dealers')) {
          this.navCtrl.navigateRoot('/dealers/data');
          return false;
        } else if ((currentRouteUrl === '/tabs/menu') || (currentRouteUrl === '/menu')) {
          this.navCtrl.navigateRoot('/menu');
          return false;
        }
      }
      return true;
    } else {
      const user = await this.authService.getLocalUser();
      if (user) {
        if (this.coredataService.isBrowser) {
          const currentRouteUrl = this.router.url.split('?')[0];
          if ((currentRouteUrl === '/tabs/buyer') || (currentRouteUrl === '/buyer')) {
            this.navCtrl.navigateRoot('/buyer/data');
            return false;
          } else if ((currentRouteUrl === '/tabs/seller') || (currentRouteUrl === '/seller')) {
            this.navCtrl.navigateRoot('/seller/data');
            return false;
          } else if ((currentRouteUrl === '/tabs/dealers') || (currentRouteUrl === '/dealers')) {
            this.navCtrl.navigateRoot('/dealers/data');
            return false;
          } else if ((currentRouteUrl === '/tabs/menu') || (currentRouteUrl === '/menu')) {
            this.navCtrl.navigateRoot('/menu');
            return false;
          }
          return true;
        }
      } else {
        return false;
      }
    }
  }
}

// Translate
import { LANG_IT_TRANS } from './lang/lang-it';

// translation token
export const TRANSLATIONS = 'translations';

// all translations
export const dictionary = {
    it: LANG_IT_TRANS,

};

export const TRANSLATION_PROVIDERS = [
    { provide: 'translations', useValue: dictionary },
];

/* CORE */
import { Component, OnDestroy, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Platform, NavController, AlertController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Storage } from '@ionic/storage';
import { Subscription } from 'rxjs';
import { AppVersion } from '@ionic-native/app-version/ngx';

/* PROVIDERS */
/* Common Services */
import { EnvService } from './services/env.service';
/* PROVIDER AuthService - Auth and User Dtata */
import { AuthService } from './services/auth.service';
/* PROVIDER CoredataService - Core Backend App Data */
import { CoredataService } from './services/coredata.service';
import { MapService } from './services/map.service';
import { FcmService } from './services/fcm.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {

  /* NETWORK Observables
  */
  public connectSubscription: Subscription;
  public disconnectSubscription: Subscription;
  isConnected = true;

  alert: any = null;
  type: string = null;
  userId = null;
  user: any = null;

  private userLoginSubjSub: Subscription;

  // selectedMenu = 'buyer';
  // selectedItem = '/buyer/data';

  constructor(
    private appVersion: AppVersion,
    private router: Router,
    private location: Location,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navCtrl: NavController,
    private network: Network,
    private storage: Storage,
    public menuCtrl: MenuController,
    private zone: NgZone,
    private env: EnvService,
    private authService: AuthService,
    public coredataService: CoredataService,
    private mapService: MapService,
    private fcmService: FcmService,
    public alertController: AlertController,
  ) {
    this.initializeApp();
    this.clearIOSInitialBadge();
  }

  handleNotificationCount() {
    this.coredataService.getNotificationsCount();
  }

  clearIOSInitialBadge() {
    this.fcmService.setBadgeNumber(0);
  }

  initializeApp() {

    this.env.consoleLog('APP INITIALIZE START');

    this.platform.ready().then(() => {
      this.mapService.loadMap().then(
        () => this.env.consoleLog('MAP LOADED'),
        () => this.env.consoleLog('MAP LOADING FAILED')
      );

      if (this.platform.is('cordova')) {

        this.appVersion.getVersionNumber().then(version => {
          let payload = {
            os: 'android',
            version: version
          }

          if (this.platform.is('ios')) {
            payload.os = 'ios'
          }

          this.coredataService.checkAppVersion(payload).subscribe((data) => {
            this.env.consoleLog("APP VERSION", version);
            this.env.consoleLog("VERSION STATUS", data.status);
            if (data.status) {
              this.initialize();
            } else {
              this.navCtrl.navigateRoot('/redirect-to-stores');
              this.splashScreen.hide();
            }
          })
        })

      } else {
        this.initialize();
      }
    });
  }

  initialize() {
    if (this.platform.is('android')) {
      this.statusBar.overlaysWebView(false)
      this.statusBar.styleDefault();
      this.statusBar.styleLightContent(); //Status bar color, light if you have dark header
      this.statusBar.backgroundColorByHexString('#2080C0');
    }

    this.menuCtrl.enable(true);

    this.storage.ready().then(async () => {
      this.userLoginSubjSub = this.authService.userLoginSubj.subscribe({
        next: (msg) => {

          this.env.consoleLog('userLoginSubjSub', msg);

          // USER LOGOUT HAPPENS
          if (msg && (typeof msg !== 'object') && (msg === 'logout')) {
            // UNSET USER
            this.userId = null;
            this.type = null;
            this.user = null;

          }

          // USER LOGIN HAPPENS
          if (msg && (typeof msg === 'object') && msg.id) {

            // SET USER
            this.user = msg;
            this.userId = this.user.id;
            this.type = this.user.type;
            this.coredataService.getAttributesData();
          }
        },
      });

      this.env.consoleLog("URL", this.location.path())

      this.env.consoleLog('STORAGE READY');
      if (this.location.path().includes('/credit-card-transfer')
        || this.location.path().includes('/recharges')
        || this.location.path().includes('/credit-collection')
      ) return;

      // if (!this.location.path().includes('/credit-card-transfer') || !this.location.path().includes('/recharges')) {
      const tutorialIsRead = 'tutorialIsRead';
      const key = 'wfmae' + '_' + this.env.ENV + '_' + /* this.env.VERSION + '#' + */ tutorialIsRead;
      const isTutorialRead = await this.storage.get(key);
      this.env.consoleLog('(component) isTutorialRead', isTutorialRead);

      if (isTutorialRead) {
        const user = await this.authService.getLocalUser();
        this.env.consoleLog('(component) AuthPage can activate this user?', user);

        // IS USER LOGGED IN? YES
        if (user && user.id) {
          this.env.pageRoot = this.env.pageRootDefault;
        }
        // IS USER LOGGED IN? NO
        else {
          this.navCtrl.navigateRoot('/entrance');
        }

      } else {
        this.navCtrl.navigateRoot('/tutorial');
      }
      // }
    });

    if (this.platform.is('cordova')) {
      // SPLASH HIDE
      this.env.consoleLog('splashScreen.hide');
      setTimeout(() => {
        this.splashScreen.hide();
      }, 4000);

      // NETWORK STATUS HOOKS
      this.networkStatusObserve();

    }
  }

  /* NETWORK STATUS HOOKS
  */
  async networkStatusObserve() {

    if (this.network.type === 'none' || this.network.type === 'unknown') {
      this.env.consoleLog('network disconnected');
      this.zone.run(() => this.isConnected = false);
      this.alert = await this.alertController.create({
        header: 'Alert',
        // subHeader: 'Subtitle',
        message: 'Connessione assente.',
        buttons: [],
        backdropDismiss: false,
      });
      this.alert.present();
    } else {
      this.env.consoleLog('network connected');
      this.zone.run(() => this.isConnected = true);
      if (this.alert) {
        this.alert.dismiss();
      }
    }
    this.disconnectSubscription = this.network.onDisconnect().subscribe(async () => {
      this.env.consoleLog('network disconnected');
      this.zone.run(() => this.isConnected = false);
      this.alert = await this.alertController.create({
        header: 'Alert',
        // subHeader: 'Subtitle',
        message: 'Connessione assente.',
        buttons: [],
        backdropDismiss: false,
      });
      this.alert.present();
    });
    this.connectSubscription = this.network.onConnect().subscribe(() => {
      this.env.consoleLog('network connected');
      this.zone.run(() => this.isConnected = true);
      if (this.alert) {
        this.alert.dismiss();
      }
    });
  }

  ngOnDestroy() {

    this.env.consoleLog('=== AppComponent ngOnDestroy ===========');

    if (this.platform.is('cordova')) {
      this.connectSubscription.unsubscribe();
      this.disconnectSubscription.unsubscribe();
    }

  }

  /* BROWSER */

  closeMenu() {
    this.menuCtrl.close();
  }

  selectMenu(selectedMenu) {
    // this.coreselectedMenu = selectedMenu;
    this.coredataService.selectedMenu = selectedMenu;
    this.coredataService.getUserPreferencesData();
  }

  selectItem(selectedItem) {
    switch (selectedItem) {
      case '/buyer/data':
      case '/buyer/map':
        this.coredataService.selectedItem = selectedItem;
        this.coredataService.selectedMenu = 'buyer';
        return;
      case '/seller/data':
      case '/seller/map':
        this.coredataService.selectedItem = selectedItem;
        this.coredataService.selectedMenu = 'seller';
        return;
      case '/dealers/data':
      case '/dealers/map':
        this.coredataService.selectedItem = selectedItem;
        this.coredataService.selectedMenu = 'dealers';
        return;
      case '/menu':
      case '/chat-list':
      case '/profile-tickets-hist':
      case '/profile-transactions-history':
        this.coredataService.selectedItem = selectedItem;
        this.coredataService.selectedMenu = 'menu';
        return;
      case '/profile-info':
      case '/profile-competences':
      case '/contact-us':
      case '/forgot-password':
      case '/app-notification':
        this.coredataService.selectedItem = selectedItem;
        this.coredataService.selectedMenu = 'profile';
        return;
      default:
        return;
    }
  }

  logout() {
    this.coredataService.logout();
  }
}

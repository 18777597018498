import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../service/translate.service'; // our translate service

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(value: any, args?: any): any {
    if (!value) {
      return;
    }
    return this.translate.instant(value);
  }

}

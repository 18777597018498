import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: "root",
})
export class MapService {
  private _apiLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get apiLoaded() {
    return this._apiLoaded.asObservable();
  }
  constructor(private http: HttpClient, private env: EnvService) {}

  loadMap(): Promise<any> {
    
    const win = window as any;
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.env.GOOGLEMAPS_APIKEY}&language=it&region=IT&callback=onload`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      onload = () => {     
        const googleModule2 = win.google;
        if (googleModule2 && googleModule2.maps) {
          resolve(googleModule2.maps);
        } else {
          reject("google maps not available");
        }
      };
    });
  }
  getGoogleMaps(): Promise<any> {
    const win = window as any;
    const googleModule = win.google;
    if (googleModule && googleModule.maps) {
      return Promise.resolve(googleModule.maps);
    }

    return this.loadMap();

  }
}

// lang-it.ts

export const LANG_IT_TRANS = {

  // ROLES
  'technician': 'Tecnico',
  'buyer': 'Compratore',
  'seller': 'Venditore',
  'vendor': 'Venditore',
  'vendor-technician': 'Entrambi',

  // GENERIC ACTIONS
  'action-ok': 'OK',
  'action-no': 'No',
  'action-yes': 'Si',
  'action-accept': 'Accetta',
  'action-logout': 'Logout',
  'action-send': 'Invia',
  'action-close': 'Chiudi',
  'action-save': 'Salva',
  'action-add': 'Aggiorna',
  'action-upd': 'Modifica',
  'action-del': 'Elimina',
  'action-confirm': 'Conferma',
  'action-cancel': 'Annulla',
  'action-proceed': 'Procedi',
  'action-continue': 'Continua',
  'action-select-this': 'Scegli',
  'action-clear': 'Pulisci',
  'action-clear-all': 'Pulisci',
  'action-select-all': 'Sel. tutto',
  'action-select-all-f': 'Sel. tutte',
  'action-more': 'Di più',
  'action-search': 'Cerca',
  'action-apply': 'Applica',
  'action-done': 'Fatto',
  'action-create-new-ticket': 'crea nuovo ticket',

  'Search': 'Cerca...',

  'no-info': 'Nessuna informazione disponibile',

  // GENERIC LABELS
  'lbl-user-email': 'Email',
  'lbl-user-password': 'Password',
  'lbl-user-password2': 'Ripeti password',
  'lbl-user-name': 'Nome',
  'lbl-name': 'Nome',
  'lbl-user-surname': 'Cognome',
  'lbl-user-company': 'Ragione sociale',
  'lbl-user-address': 'Indirizzo',
  'lbl-user-telephone': 'Numero di Telefono',
  'lbl-telephone': 'Telefono',
  'lbl-user-fiscal-code': 'Codice Fiscale',
  'lbl-user-vat': 'Partita IVA',
  'lbl-fiscal-profile': 'Profilo fiscale',

  // GENERIC TEXTS
  'txt-account-need-to-be-validated': 'Il tuo account deve essere ancora validato dal team ArchimedePRO.',
  'txt-account-need-to-be-validated-2': ' Fino a quando non sarà verificato, alcune funzionalità non saranno disponibili.',
  'txt-no-data-found': 'No data found',

  // GENERIC ERRORS
  'err-invalid': 'Non valido',
  'err-required': 'Campo obbligatorio',
  'err-maxlength': 'Testo troppo lungo',
  'err-password-minlength': 'La password deve essere di almeno 8 caratteri',
  'err-password-maxlength': 'Password troppo lunga',
  'err-password-no-match': 'Attenzione le password non corrispondono',
  'err-password-weak': 'Password debole. La password deve contenere almeno 1 lettera maiuscola, 1 carattere numerico e 1 carattere speciale.',
  'err-no-email': 'Email non riconosciuta',
  'err-no-fiscal-code': 'Codice fiscale non riconosciuto',
  'err-no-unique-code': 'Codice univoco non riconosciuto',
  'err-fiscal-code-length': 'il codice fiscale deve essere di 16 cifre',
  'err-vat-length': "l'iva deve essere di 11 cifre",

  'err-no-name': 'Nome con caratteri non accettati',
  'err-name-minlength': 'Nome troppo corto',
  'err-name-maxlength': 'Nome troppo lungo',

  'err-no-surname': 'Cognome con caratteri non accettati',
  'err-surname-minlength': 'Cognome troppo corto',
  'err-surname-maxlength': 'Cognome troppo lungo',

  'err-no-company': 'Ragione sociale con caratteri non accettati',
  'err-company-minlength': 'Ragione sociale troppo corto',
  'err-company-maxlength': 'Ragione sociale troppo lungo',

  'err-no-telephone': 'Telefono non riconosciuto',
  'err-telephone-maxlength': 'Telefono troppo lungo',

  'err-no-problem-description': 'Inserire una descrizione testuale del problema',
  'err-problem-description-maxlength': 'Descrizione troppo lunga',
  'err-problem-description-minlength': 'Descrizione troppo corta',

  'err-no-address': 'Inserire un indirizzo valido',
  'err-address-maxlength': 'Indirizzo troppo lungo',
  'err-address-minlength': 'Indirizzo troppo corto',

  'err-no-cap': 'Inserire un cap valido',
  'err-cap-maxlength': 'CAP troppo lungo',
  'err-cap-minlength': 'CAP troppo corto',

  'err-no-price': 'Inserire un prezzo valido',
  'err-price-maxlength': '',
  'err-price-minlength': '',

  'err-no-technicalNotes': 'Inserire una nota testuale (facoltativa)',
  'err-technicalNotes-maxlength': 'Nota troppo lunga (facoltativa)',
  'err-technicalNotes-minlength': 'Nota troppo corta (facoltativa)',


  'msg-something-went-wrong': 'Non è stato possibile completare l\'operazione',

  // TUTORIAL
  'page-tutorial-title': 'Tutorial',
  't1-title': 'Benvenuto',
  't1-content': `Archimede Pro è un’app dedicata ai tecnici di elettrodomestici.
  Scorri per scoprire tutte le funzionalità!`,
  't2-title': 'Acquistare assistenze',
  't2-content': `Acquista le assistenze nella tua zona di lavoro, le pagherai dopo aver fissato l’appuntamento con il cliente.`,
  't3-title': 'Vendere assistenze',
  't3-content': `Potrai vendere ai tecnici competenti selezionati da Archimede`,
  't4-title': 'Acquistare ricambi',
  't4-content': `Trova tutti i pezzi di ricambio di cui hai bisogno presso i ricambisti convenzionati `,
  't5-title': 'E molto altro!',
  't5-content': `Ricevi una notifica per le assistenze in vendita nelle tue zone, elettrodomestici e marchi di tua competenza`,
  'page-tutorial-dont-show-tutorial': 'Non mostrare più il tutorial',

  // AUTH
  'page-auth-invitation-h': 'Bentornato',
  'page-auth-login': 'Login',
  'page-auth-ask-for-registration': 'Non hai un account?',
  'page-auth-registration': 'Registrati',
  'page-auth-ask-forgot-password': 'Password dimenticata?',
  'page-auth-remember-me': 'Ricorda i dati d\'accesso',

  // AUTH -> FORGOT PASSWORD
  'page-auth-forgot-password': 'Aggiorna password',
  'page-auth-forgot-password-invitation': 'Inserisci il tuo indirizzo email per aggiornare la tua password',
  'page-forgot-password-email-no-exists': 'Email sconosciuta. Verifica e inserisci un indirizzo registrato.',

  // AUTH -> FORGOT PASSWORD FINAL
  'page-auth-forgot-password-final': 'Aggiorna password',
  'page-auth-forgot-password-final-invitation': 'Controlla le tue email e clicca sul link che ti abbiamo inviato',

  // REGISTER
  'page-register-invitation-h': 'Registrati',
  'page-register-invitation-t': 'Crea un account per continuare',
  'page-register-register': 'Registrati',
  'page-register-ask-for-auth': 'Hai già un account?',
  'page-register-auth': 'Login',
  'page-register-registered': 'Registrazione effettuata con successo.',
  'txt-fiscal-profile-ordinary': 'Ordinario',
  'txt-fiscal-profile-flat-rate': 'Forfettario',

  // REGISTER CHECK PHONE
  'page-register-check-phone-register': 'Procedi',
  'page-register-check-phone-invitation-h': 'Verifica del telefono',
  'page-register-check-phone-invitation-t': 'Inserisci il codice che ti abbiamo inviato via SMS',
  'page-register-check-phone-validate-otp-error-text': 'Il codice digitato non è corretto',
  'page-register-check-phone-ask-for-otp': 'Non hai ricevuto il codice?',
  'page-register-check-phone-send-otp': 'Riprova.',

  // APPLIANCES
  'page-appliances-me-title': 'Seleziona i dispositivi',
  'page-appliances-invitation-h': 'Elettrodomestici che tratti',
  'page-appliances-invitation-t': 'Seleziona gli elettrodomestici di cui ti occupi',
  'page-appliances-register': 'Procedi',
  'page-appliances-next': 'Salta',
  'page-appliances-title': 'Dispositivo',
  'appliances-regitration-message': 'Gli elettrodomestici che tratti sono stati registrati',

  // BRANDS
  'page-brands-me-title': 'Seleziona i marchi',
  'page-brands-invitation-h': 'I marchi di cui ti occupi',
  'page-brands-invitation-t': 'Seleziona i marchi di cui ti occupi',
  'page-brands-register': 'Procedi',
  'page-brands-next': 'Salta',
  'page-brands-title': 'Marchio',
  'page-brands-title-fem': 'Marca',
  'brands-regitration-message': 'I marchi che tratti sono stati registrati',

  // GEOGRAPHY
  'page-geography-invitation-h': 'Competenza geografica',
  'page-geography-invitation-t': 'Aggiungi le province e le tue aree di competenza',
  'page-geography-provinces': 'Aggiungi province',
  'page-geography-areas': 'Aggiungi aree',

  // PROVINCES
  'page-provinces-invitation-h': 'Province che tratti',
  'page-provinces-invitation-t': 'Selezione le province di cui ti occupi',
  'page-provinces-register': 'Procedi',
  'page-provinces-title': 'Province',
  'page-provinces-next': 'Salta',
  'page-provinces-select-all': 'Tutte le province',

  // REGISTER FINISH
  'page-register-finish-invitation-h': 'Congratulazioni',
  'page-register-finish-invitation-t1': 'Abbiamo ricevuto la tua richiesta.',
  'page-register-finish-invitation-t2': 'Ora puoi vedere tutte le assistenze pubblicate su Archimede Pro. Ti contatterà telefonicamente un nostro incaricato per concludere la tua registrazione.',

  // AUTH MESSAGES
  'page-auth-logged-in': 'Accesso avvenuto correttamente.',
  'page-auth-no-logged-in': 'Accesso non riuscito, verifica Email e Password.',
  'back-end-error': 'Accesso non riuscito. Aspetta qualche minuto e riprova.',
  'page-auth-no-registered': 'Sembra che tu non sia ancora registrato. Procedi alla registrazione.',

  // AVATAR UPDATE
  'attach-image': 'Personalizza il tuo avatar',
  'add-photo': 'Scatta una foto',
  'add-gallery': 'Aggiungi un\'immagine dalla galleria',
  'modal-image-crop-title': 'Ritaglia',

  // HOME TABS MENU (TAB1 TAB2 TAB3 TAB4)
  'action-buyer': 'Compra',
  'action-seller': 'Vendi',
  'action-dealers': 'Ricambisti',
  'action-menu': 'Menu',

  // ENTRANCE
  'entrance-landing1-h': 'Easy repair service with federigo',
  'entrance-landing1-t': 'The easiest way to solve the users issues',
  'login': 'Accedi',
  'registration': 'Registrati',
  'choose-role-invitation': 'Choose your prefered role',

  // TAB1 BUY TICKETS
  'page-tab-buyer-title': 'Assistenze in vendita',
  'lbl-assistances': 'Assistenze',
  'lbl-booked-assistances': 'Assistenze prenotate',
  'lbl-ticket-task': 'Assistenza',
  'lbl-ticket-cap': 'CAP',
  'lbl-ticket-exit': 'Uscita',
  'txt-hr': 'h',
  'txt-min': 'minuti',
  'txt-wellcome': 'Ciao',
  'segment-tickets-onsale': 'In vendita',
  'segment-tickets-sell': 'Vendi',
  'segment-tickets-map': 'Mappa',
  'buyer-search-placeholder': 'Cerca assistenze nell’elenco',
  'txt-cancellable-date': 'Annullabile entro',
  // 'txt-expired': 'Scade il',
  'txt-your-areas': 'Le tue aree',
  'txt-no-permission': 'Nessun permesso',
  'txt-buyer-sale': 'In Vendita',
  'txt-buyer-done': 'Confermata',
  'subsegment-tickets-all': 'Tutte',
  'subsegment-tickets-sale': 'In vendita',
  'subsegment-tickets-booked': 'Prenotate',
  'subsegment-tickets-done': 'Vendute',
  'subsegment-tickets-cancelled': 'Annullati',
  'subsegment-tickets-book_cancelled': 'Annullate',
  'subsegment-tickets-seller-cancelled': 'Cancellate',
  'subsegment-tickets-expired': 'Scadute',
  'buyer-subsegment-tickets-sale': 'Acquistabili',
  'buyer-subsegment-tickets-booked': 'Prenotate',
  'buyer-subsegment-tickets-done_auto': 'Confermate',


  // TAB2 SELL TICKETS
  'page-tab-seller-title': 'Le tue assistenze in vendita',
  'seller-search-placeholder': 'Cerca assistenze nell’elenco',
  'txt-status-sale': 'In vendita',
  'txt-status-booked': 'Prenotato da',
  'txt-status-done': 'Completato da',
  'txt-status-cancelled': 'Annullato da',
  'txt-status-book-cancelled': 'Annullata',
  'txt-status-expired': 'Ticket scaduto',
  'txt-posted-on': 'Pubblicato il',

  // TAB3 MENU
  'page-tab-dealers-title': 'Elenco Ricambisti',
  'dealers-search-placeholder': 'Cerca ricambisti nell’elenco',
  'lbl-dealers': 'Ricambisti',
  'segment-dealers-data': 'Ricambisti',
  'segment-dealers-map': 'Mappa',

  // TAB4 MENU
  'page-tab-menu-title': 'Menu',
  'page-menu-menu-profile': 'Il mio profilo',
  'page-menu-menu-appliances': 'Gli elettrodomestici che tratti',
  'page-menu-menu-brands': 'I marchi che tratti',
  'page-menu-menu-geography': 'Aree di competenza',
  'page-menu-menu-logout': 'Logout',

  // TAB1 / TAB3 FILTERS
  'filter': 'Filtri',
  'modal-filter-tickets-title': 'Filtra le assistenze',
  'modal-filter-dealers-title': 'Filtra i ricambisti',
  'modal-filter-order-by': 'Ordina per',
  'modal-filter-order-by-desc': 'Più recente',
  'modal-filter-order-by-asc': 'Più vecchio',
  'modal-filter-map-tickets-title': 'Seleziona l’area di competenza',
  'modal-filter-map-no-province': 'Non ci sono province preferite',
  'modal-filter-info-txt': 'Se non viene applicato nessun filtro, vedrai solo le assistenze relative alle tue preferenze.',
  'txt-any': 'Qualsiasi',
  'action-reset-to-my-preferences': 'Reset filtro',
  'action-reset-filters': 'Pulisci filtro',
  'action-apply-my-preferences': 'Applica le mie preferenze',

  // TICKET DETAIL
  'page-ticket-detail': 'Dettaglio assistenza',
  'lbl-no-fiscal-code': 'iva esclusa',
  'lbl-booked-date': 'Data di prenotazione',
  'lbl-cancelled-date': 'Data di annullamento',
  'lbl-cancelled-reason': ' Motivazione',
  'lbl-ticket-information': ' Informazioni ticket',
  'lbl-ticket-history': 'Storico ticket',
  'lbl-booked-by': 'Prenotato da',
  'lbl-book-date': 'Data prenotazione',
  'lbl-purchased-by': 'Acquistato da',
  'lbl-purchase-date': 'Data acquisto',
  'lbl-cancelled-by': 'Annullato da',
  'lbl-cancel-date': 'Data annullamento',
  'lbl-cancel-reason': 'Motivo annullamento',
  'txt-repair': 'Riparazione',
  'txt-annullable': 'Annullabile entro',
  'txt-cancel-in': 'Annulla entro',
  'txt-cancelled': 'Annullato',
  'txt-exit-condition': 'Diritto di uscita',
  'txt-exit-on-repair': 'Uscita gratuita in caso di riparazione',
  'txt-free-exit-yes': 'Si',
  'txt-free-exit-no': 'No',
  'txt-publishing-date': 'Pubblicato il',
  'txt-publishing-time': 'alle',
  'txt-select-phone-number': 'Seleziona il numero di telefono',
  'txt-buyer-not-authorized': 'La sua utenza non è abilitata all’acquisto di assistenze',
  'txt-seller-not-authorized': 'La sua utenza non è abilitata alla vendita di assistenze',
  'ticket-customer-data-subtitle': 'Informazioni del cliente',
  'action-update-ticket': 'Modifica Assistenza',
  'action-save-ticket': 'Salva assistenza',
  'action-cancel-ticket': 'Annulla Assistenza',
  'action-buy': 'Acquista',
  'action-buyer-sale': 'Prenota',
  'action-buyer-booked': 'Conferma',
  'action-not-interested': 'Non sono interessato',
  'action-cancel-buy': 'Annulla acquisto',
  'action-call-customer': 'Chiama il cliente',
  'action-contact-seller': 'Contatta il venditore',
  'action-contact-buyer': 'Contatta l’acquirente',
  'action-resale-ticket': 'Rivendi',
  'action-expand': 'Espandi',
  'action-reverse': 'Storno',

  // TICKET DETAIL - Not Interested Reason Alert
  'txt-which-not-interested-reasons': 'Per quale motivo non sei interessato?',
  'txt-not-interested-reasons-area': 'Non gestisco questo',
  'txt-not-interested-reasons-area-spec': 'CAP (CAP number)',
  'txt-not-interested-reasons-brand': 'Non gestisco',
  'txt-not-interested-reasons-appliance': 'Non gestisco',
  'txt-booked-by-another': 'L’assistenza è già stata prenotata da un altro utente',

  'popup-confirm-assistance': 'Sei sicuro di voler confermare l’assistenza ?',
  'popup-reverse-assistance': 'Sei sicuro di voler stornare l’assistenza? Perderai i crediti accumulati dalla vendita e sarà trattenuta la commissione di 3alCubo.',
  'assistance-reversed-message': 'Storno avvenuto con successo',

  // TICKET DETAIL - PAYMENT START Popup
  'popup-buy-title': 'Conferma prenotazione',
  // 'popup-buy-text': 'Portai annullare l’acquisto senza addebiti entro le',
  'popup-buy-text-1': 'puoi annullare senza addebiti entro il',
  'popup-buy-text-2': 'alle ore',
  'popup-buy-price-with-vat': 'Prezzo con Iva',
  'popup-buy-price-without-vat': 'Iva esclusa',

  // TICKET DETAIL - PAYMENT FINISH RESULTS Popup
  'popup-payment-finish-title': ' Prenotazione confermata',
  // 'popup-payment-finish-text': 'Il pagamento dell’assistenza #1234 è avvenuto con successo',
  // 'popup-payment-finish-text-1': 'Il pagamento dell’assistenza',
  // 'popup-payment-finish-text-2': 'è avvenuto con successo',
  'popup-payment-finish-text': 'Assistenza',
  'popup-payment-fails-title': 'Prenotazione non avvenuta',
  'popup-payment-fails-text': 'Non è stato possibile procedere alla prenotazione',
  'action-call-customer-now': 'chiama subito il cliente',

  // TICKET DETAIL - Cancel Booked Ticket Reason Modal
  'modal-cancelbuy-reasons-title': 'Annulla acquisto',
  'modal-cancelbuy-give-reason': 'Motiva l\'annullamento',
  'modal-cancelbuy-give-reason2': 'Motiva la tua risposta',
  'cancelbuy-reason-other': 'Altro (specificare)',

  // TICKET DETAIL - Cancel Ticket by Seller Modal
  'modal-cancel-ticket-title': 'Conferma',
  'modal-cancel-ticket-text': 'Sei sicuro di voler annullare l’assistenza?',

  // TICKET CREATE / UPDATE
  'page-ticket-seller-create': 'Vendi nuova assistenza',
  'page-ticket-seller-update': 'Modifica assistenza',
  'placeholder-appliance': 'Scegli l’elettrodomestico',
  'placeholder-brand': 'Scegli la marca',
  'placeholder-province': 'Scegli la provincia',
  'placeholder-zipcode': 'Scegli il CAP',
  'lbl-appliance': 'Tipo di elettrodomestico',
  'lbl-brand': 'Marca elettrodomestico',
  'lbl-request-date': 'Data della richiesta',
  'lbl-requested-date-yesterday': 'Ieri',
  'lbl-requested-date-today': 'Oggi',
  'lbl-customer-name': 'Nome del cliente',
  'lbl-customer-phone': 'Numero di telefono',
  'lbl-problem-description': 'Problema da segnalare',
  'lbl-address': 'Indirizzo',
  'lbl-provinces': 'Province',
  'lbl-zipcodes': 'CAP',
  'lbl-province': 'Provincia',
  'lbl-zipcode': 'CAP',
  'lbl-municipality': 'Comune',
  'lbl-select-municipality': 'Seleziona Comune',
  'lbl-street': 'Via',
  'lbl-civic': 'Civico',
  'lbl-country': 'Stato',
  'lbl-unique-code': 'Codice univoco',
  'lbl-ticket-price': 'Prezzo del ticket',
  'lbl-exit-price': 'Prezzo d’uscita',
  'lbl-expiry-date': 'Data di scadenza',
  'lbl-technical-notes': 'Nota per il tecnico',
  'txt-exit-condition-type': 'Condizioni d’uscita',
  'txt-exit-condition-free': 'Da comunicare',
  'txt-exit-condition-gratis': 'Uscita gratuita',
  'txt-exit-condition-price': 'Prezzo',
  'txt-exit-free': 'Uscita gratis in caso di riparazione',
  'txt-buyer-price': 'Prezzo per l’acquirente (incluso di commisioni)',
  'txt-actual-gain': 'Guadagno effettivo',
  'txt-select-date': 'Seleziona una data',
  'txt-accept-terms-first': 'Acconsento ai',
  'txt-accept-terms-second': 'Termini e condizioni',
  'txt-accept-policy-first': 'Accetto la',
  'txt-accept-policy-second': 'privacy policy',

  // TICKET CREATE COMPLETION
  'page-ticket-seller-create-finish': 'Vendi nuova assistenza',
  'txt-heading': 'Completato!',
  'txt-msg': 'La tua assistenza è stata creata con successo. Ti ricordiamo che il tuo ticket scadrà se non verrà acquistato entro le ore ',
  'action-create-new-assistance': 'crea nuova assistenza',
  'action-back-to-sale': 'torna alle assistenza in vendita',

  // DEALER DETAIL
  'page-dealer-detail': 'Dettaglio Ricambista',
  'dealer-title': 'Informazioni personali',
  'dealer-appliances-brands-title': 'Marchi ed elettrodomestici trattati',
  'lbl-devices': 'Elettrodomestici',
  'lbl-brands': 'Marchi',
  'lbl-categories': 'Categorie',

  // PROFILE
  'page-profile-detail': 'Il mio profilo',
  'profile-update': 'Modifica il tuo profilo',
  'profile-info': 'Informazioni personali',
  'profile-competences': 'Competenze',
  'profile-geographic-area': 'Aree di competenza geografica',
  'profile-tickets-stat': 'Statistiche',
  'profile-transactions-history': 'Bilancio credito',
  'profile-contact-us': 'Contattaci',
  'profile-change-password': 'Cambia Password',
  'profile-delete-profile': 'Elimina account',
  'profile-delete-profile-confirmation': 'Confermi di voler cancellare il tuo account? Tutti i tuoi dati saranno cancellati definitivamente. Scopri di più su Termini e Condizioni.',
  'profile-delete-profile-confirmation-err': 'Sembra che tu abbia ancora delle assistenze attive: prima di procedere con la cancellazione dell’account, assicurati di non avere assistenze in gestione o in vendita.',
  'profile-delete-profile-confirmation-success': 'La tua richiesta di cancellazione dell’account ArchimedePRO è stata presa in carico dal nostro team, ti invieremo una mail ad operazione conclusa.',

  // PROFILE INFO
  'page-profile-info-detail': 'Informazioni personali',
  'user-competences-subtitle': 'Le tue competenze',
  'user-geography-subtitle': 'Aree geografiche di competenza',
  'action-update-competences': 'Modifica Competenze',
  'action-update-geography': 'Modifica Aree Geografiche',

  // PROFILE UPDATE
  'page-profile-update-detail': 'Aggiorna Profilo',

  // PROFILE COMPETENCE AREA
  'page-profile-geography-detail': 'Aree di competenza',

  // PROFILE COMPETENCES
  'page-profile-competences-detail': 'Competenze',
  'segment-competences-has': 'Trattati',
  'segment-competences-has-not': 'Non Trattati',
  'lbl-competence-brand': 'Marca',
  'lbl-competence-appliance': 'Elettrodomestici',


  // PROFILE TICKETS HIST
  'page-profile-tickets-stat': 'Statistiche',
  'segment-sell': 'Venduto',
  'segment-buy': 'Acquistato',
  'lbl-tickets-sale': 'Aperti',
  'lbl-tickets-done': 'Venduti',
  'lbl-tickets-cancelled': 'Annullati',
  'lbl-tickets-expired': 'Scaduti',
  'lbl-tickets-purchased-sale': 'Aperti',
  'lbl-tickets-purchased-done': 'Acquistati',
  'lbl-tickets-purchased-cancelled': 'Annullati',
  'lbl-tickets-purchased-expired': 'Scaduti',

  // PROFILE TRANSACTIONS HIST
  'page-profile-transactions-hist': 'Bilancio credito',
  'lbl-transaction': 'Transazioni',
  'lbl-transaction-total': 'Bilancio totale',
  'lbl-transaction-in': 'Entrate',
  'lbl-transaction-out': 'Uscite',
  'lbl-credit-remain': 'Credito disponibile',
  'lbl-select-date': 'Seleziona un range di date',
  'lbl-start-date': 'Data di inizio',
  'lbl-end-date': 'Data di fine',
  'lbl-search-list': 'Ricerca nell’elenco',
  'modal-filter-transactions-title': 'Filtra lo bilancio credito ',
  'txt-storno': 'Storno',

  // PROFILE CONTACT US
  'page-contact-us': 'Contattaci',
  'lbl-name-and-surname': 'Nome e Cognome',
  'lbl-your-email': 'La tua Email',
  'lbl-message': 'Il tuo messaggio',
  'lbl-subject': 'Oggetto',

  // PROFILE TRANSACTIONS FILTER
  'lbl-user': 'Utenti',
  'lbl-typology': 'Tipologia',

  //chat
  'reply-message': "Rispondi al messaggio",
  'message-list': "Messaggi",
  'message-ticket-label': "ASSISTENZA",
  'see-ticket': 'Vedi ticket',

  //notifications
  'notification': "Notifiche",

  'tickets': "Tickets",

  'txt-agreed-price': 'prezzo concordato',
  'txt-premium-price': '*',

  'txt-required-tax-or-vat': 'Compila almeno un campo tra Codice fiscale e Partita IVA',
  'txt-profile-updated': 'Profilo aggiornato con successo'
};

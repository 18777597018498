
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { of, Observable, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { EnvService } from './env.service';
import { UserData, UserDataRegister, UserAvatarDefault } from '../models/core';
import { BehaviorSubject } from 'rxjs';

declare var window;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userDataRegister: UserDataRegister;

  private _user: UserData = null;
  private _auth: any = null;
  private _wasOtpSent: boolean = false;
  private _selectedTab: string = null;

  isLoggedIn = false;
  type: string = null;
  token: any;
  language = navigator.language.slice(0, 2);
  credentials: any = null;

  public userLoginSubj = new BehaviorSubject<any>(
    'start'
  );
  public selectedTabDefault = '/tabs/tab-tourist-guide';

  constructor(
    private platform: Platform,
    private http: HttpClient,
    private storage: Storage,
    private env: EnvService,
  ) {
    this.platform.ready().then(() => {
      this.storage.ready().then(() => {
        this.getLocalUser().then(
          user => {
            this.user = user;
            this.env.consoleLog('AuthService user', this.user);
            this.getLocalAuth().then(
              auth => {
                this.auth = auth;
                this.env.consoleLog('AuthService auth', this.auth);
                if (this.auth) {
                  this.userLoginSubj.next(this.user);
                }
              });
          });
      });
    });
  }

  get user() {
    return this._user;
  }
  set user(value: UserData) {
    this._user = value;
  }
  get auth() {
    return this._auth;
  }
  set auth(value: any) {
    this._auth = value;
  }
  get wasOtpSent() {
    return this._wasOtpSent;
  }
  set wasOtpSent(value: boolean) {
    this._wasOtpSent = value;
  }

  async setLocalCredentials(credentials): Promise<any> {
    this.credentials = credentials;
    return await this.storage.set(this.env.PRJKEY + this.env.VERSION + this.env.ENV + '#' + 'credentials', credentials || null);
  }
  async getLocalCredentials(): Promise<any> {
    return this.credentials || await this.storage.get(this.env.PRJKEY + this.env.VERSION + this.env.ENV + '#' + 'credentials') || null;
  }
  async removeLocalCredentials(): Promise<any> {
    this.credentials = null;
    return await this.storage.remove(this.env.PRJKEY + this.env.VERSION + this.env.ENV + '#' + 'credentials');
  }

  login(body: any): Observable<any> {
    this.env.consoleLog('login body', body);
    // */
    const apiMethod = this.env.API_URL + `login?locale=${this.language}`;
    return this.http.post<any>(apiMethod, JSON.stringify(body), {
      headers: {
        'Content-Type': 'application/json',
        // Authorization : this.env.AUTHTOKEN,
      },
      observe: 'response'
    }).pipe(tap(res => this.env.consoleLog('ssssss', res)),
      map(res => {
        return {
          user: res?.body?.data?.user || null,
          auth: {
            'access-token': res?.body?.data?.token || null,
            'refresh-token': res?.body?.data?.refresh_token || null
          }
        };
      })
    );
  }

  validateToken(): Observable<any> {
    const apiMethod = this.env.API_URL + `auth/validate_token?locale=${this.language}`;
    return this.http.get<any>(apiMethod, {
      headers: {
        'Content-Type': 'application/json',
        //Authorization : this.env.AUTHTOKEN,
        'access-token': this.auth['access-token'] || '',
      },
      observe: 'response'
    }).pipe(
      map(res => {
        if (res && res.status !== 200) {
          this.env.consoleLog('Validation fails with status: ', res.status);
          return res;
        }

        return {
          user: res.body.data || null,
          auth: {
            'access-token': res.headers.has('access-token') ? res.headers.get('access-token') : null,
            'client': res.headers.has('client') ? res.headers.get('client') : null,
            'uid': res.headers.has('uid') ? res.headers.get('uid') : null,
          }
        };
      })
    );
  }

  logout(): Observable<any> {
    const userLogoutRegister = {
      email: this.user?.email,
      password: ""
    }
    const apiMethod = this.env.API_URL + `logout?locale=${this.language}`;
    this.userLoginSubj.next('logout');

    return this.http.post<any>(apiMethod, JSON.stringify(userLogoutRegister), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': (this.auth && this.auth['access-token']) ? 'Bearer ' + this.auth['access-token'] : null,
      },
      observe: 'response'
    });
  }

  register(userDataRegister): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const apiMethod = this.env.API_URL + `registerUser?locale=${this.language}`;
    return this.http.post<any>(apiMethod, JSON.stringify(userDataRegister), httpOptions);
  }

  sendOtp(phoneNumber): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const apiMethod = this.env.API_URL + `resend-otp?locale=${this.language}`;
    return this.http.post<any>(apiMethod, JSON.stringify(phoneNumber), httpOptions);
  }

  validateOtp(otp): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const apiMethod = this.env.API_URL + `verify-otp?locale=${this.language}`;
    return this.http.post<any>(apiMethod, JSON.stringify(otp), httpOptions);
  }

  registerPreferences(preferences): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const apiMethod = this.env.API_URL + `preferences?locale=${this.language}`;
    return this.http.post<any>(apiMethod, JSON.stringify(preferences), httpOptions);
  }

  changePassword(userDataForgotPassword): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: this.env.AUTHTOKEN
      })
    };
    const apiMethod = this.env.API_URL + `forgot-password?locale=${this.language}`;
    return this.http.post<any>(apiMethod, JSON.stringify(userDataForgotPassword), httpOptions);
  }

  async setLocalUser(value): Promise<any> {
    this.user = value;
    const variable = 'user';
    const key = 'wfmae' + '_' + this.env.ENV + '_' + this.env.VERSION + '#' + variable;
    this.user = value;
    return await this.storage.set(key, value || null);
  }
  async getLocalUser(): Promise<any> {
    const variable = 'user';
    const key = 'wfmae' + '_' + this.env.ENV + '_' + this.env.VERSION + '#' + variable;
    return this.user || await this.storage.get(key) || null;
  }
  async removeLocalUser(): Promise<any> {
    this.user = null;
    const variable = 'user';
    const key = 'wfmae' + '_' + this.env.ENV + '_' + this.env.VERSION + '#' + variable;
    return await this.storage.remove(key);
  }

  async setLocalAuth(value): Promise<any> {
    const variable = 'auth';
    const key = 'wfmae' + '_' + this.env.ENV + '_' + this.env.VERSION + '#' + variable;
    this.auth = value;
    if (this.auth && this.auth['access-token']) {
      this.userLoginSubj.next(this.user);
      // this.wfmaeService.getUserLamps(this.user.id);
    }
    return await this.storage.set(key, value || null);
  }
  async setAuthAfterValidation(value): Promise<any> {
    const variable = 'auth';
    const key = 'wfmae' + '_' + this.env.ENV + '_' + this.env.VERSION + '#' + variable;
    this.auth = value;
    return await this.storage.set(key, value || null);
  }
  async getLocalAuth(): Promise<any> {
    const variable = 'auth';
    const key = 'wfmae' + '_' + this.env.ENV + '_' + this.env.VERSION + '#' + variable;
    return this.auth || await this.storage.get(key) || null;
  }
  async removeLocalAuth(): Promise<any> {
    this.auth = null;
    const variable = 'auth';
    const key = 'wfmae' + '_' + this.env.ENV + '_' + this.env.VERSION + '#' + variable;
    return await this.storage.remove(key);
  }

  refreshToken() {
    return this.http.post(`${this.env.API_URL}refresh`, null, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': (this.auth && this.auth['access-token']) ? 'Bearer ' + this.auth['access-token'] : null,
        'Refresh-token': (this.auth && this.auth['refresh-token']) ? this.auth['refresh-token'] : null
      }
    })
  }
}

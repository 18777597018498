import {NgModule} from '@angular/core';

// Translate
import { TRANSLATION_PROVIDERS, TranslateService, TranslatePipe } from './';

@NgModule({
  declarations: [ TranslatePipe ],
  imports: [ ],
  exports: [ TranslatePipe ],
  providers: [
    TRANSLATION_PROVIDERS,
    TranslateService
  ]
})

export class TranslateModule {}

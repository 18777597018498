import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";

@Injectable({ providedIn: "root" })
export class EnvService {
  public pageRootDefault = "/tabs/buyer";
  public pageRoot = this.pageRootDefault;

  public language = navigator.language.slice(0, 2);

  /* Centralized enable / disable Console Logs */
  showLogs = true;
  public consoleLog = this.showLogs
    ? console.log.bind(window.console)
    : () => { };

  public PRJKEY = "wfmae";
  public VERSION = "1.0.0";
  public ENV = "prod"; // 'develop'|'staging'|'prod';

  /* ===============================================================
  DEFAULT: DEVELOP
  */
  public API_URL = "https://federigo-nightly.kotukodev.it/api/";
  public APP_URL = "https://federigo-nightly-app.kotukodev.it/";
  //public AUTHTOKEN = 'Bearer 1UYToIF3NvWprUrgQBYgRQ';
  public GOOGLEMAPS_APIKEY = "AIzaSyCrS3a0UxfIff0Bhr6ltmaKmcPc-yajaHU";

  /* ===============================================================
  STAGING
  */
  public API_URL_STAGING = "https://federigo-staging.kotukodev.it/api/";
  public APP_URL_STAGING = "https://federigo-staging-app.kotukodev.it/";
  //public AUTHTOKEN_STAGING = 'Bearer 1UYToIF3NvWprUrgQBYgRQ';
  public GOOGLEMAPS_APIKEY_STAGING = "AIzaSyCrS3a0UxfIff0Bhr6ltmaKmcPc-yajaHU";
  /* ===============================================================
  PROD
  */
  public API_URL_PROD = "https://marketplace.archimedepro.it/api/";
  public APP_URL_PROD = "https://app.archimedepro.it/"
  //public AUTHTOKEN_PROD = 'Bearer 1UYToIF3NvWprUrgQBYgRQ';
  public GOOGLEMAPS_APIKEY_PROD = "AIzaSyDMb93GVEFrJbn_YWOE0Ap-HLAYylZYQnU";

  public APP_LINK = "";

  public ANDROID_LINK = "https://appdistribution.firebase.google.com/testerapps/1:900630839708:android:0c911bfa290d801d584859/"
  public IOS_LINK = "https://appdistribution.firebase.google.com/testerapps/1:900630839708:ios:fa35a5acc1128304584859/"

  public ANDROID_LINK_STAGING = "https://appdistribution.firebase.google.com/testerapps/1:900630839708:android:0c66665cd328d6fe584859/"
  public IOS_LINK_STAGING = "https://appdistribution.firebase.google.com/testerapps/1:900630839708:ios:b382e1f0742c76dc584859/"

  public ANDROID_LINK_PROD = "https://play.google.com/store/apps/details?id=it.archimedepro";
  public IOS_LINK_PROD = "https://apps.apple.com/us/app/archimede-marketplace/id6444481060";

  constructor(private platform: Platform) {
    if (this.platform.is('ios')) {
      this.APP_LINK = this.IOS_LINK;
      if (this.ENV === "staging") {
        this.APP_LINK = this.IOS_LINK_STAGING;
      }
      if (this.ENV === "prod") {
        this.APP_LINK = this.IOS_LINK_PROD;
      }
    } else {
      this.APP_LINK = this.ANDROID_LINK;
      if (this.ENV === "staging") {
        this.APP_LINK = this.ANDROID_LINK_STAGING
      }
      if (this.ENV === "prod") {
        this.APP_LINK = this.ANDROID_LINK_PROD;
      }
    }

    if (this.ENV === "staging") {
      this.API_URL = this.API_URL_STAGING;
      this.APP_URL = this.APP_URL_STAGING;
      //this.AUTHTOKEN = this.AUTHTOKEN_STAGING;
      this.GOOGLEMAPS_APIKEY = this.GOOGLEMAPS_APIKEY_STAGING;
    }
    if (this.ENV === "prod") {
      this.API_URL = this.API_URL_PROD;
      this.APP_URL = this.APP_URL_PROD;
      //this.AUTHTOKEN = this.AUTHTOKEN_PROD;
      this.GOOGLEMAPS_APIKEY = this.GOOGLEMAPS_APIKEY_PROD;
    }
  }

  getDateComponentFromString(date, dateComponent) {
    let yyyy = "";
    let mm = "";
    let dd = "";
    let splittedDate: string[] = null;
    if (date) {
      splittedDate = date.split("-");
      if (splittedDate.length === 3) {
      } else {
        splittedDate = date.split("/");
      }
      if (splittedDate[2].length > splittedDate[0].length) {
        yyyy = splittedDate[2];
        mm = splittedDate[1];
        dd = splittedDate[0];
      } else {
        yyyy = splittedDate[0];
        mm = splittedDate[1];
        dd = splittedDate[2];
      }
      if (dateComponent === "yyyy") {
        return yyyy;
      } else if (dateComponent === "yyyy") {
        return mm;
      } else if (dateComponent === "mm") {
        return mm;
      } else if (dateComponent === "dd") {
        return dd;
      } else {
        return dd + "/" + mm + "/" + yyyy;
      }
    }
    return "";
  }

  /* Format amount value
   */
  formatAmount(valString, groupSeparator, decimalSeparator) {
    if (!valString) {
      return "0";
    }
    const val = valString.toString();
    const parts = val.split(".");
    let decimals = null;
    if (parts[1]) {
      if (parts[1].length === 1) {
        decimals = parts[1] + "0";
      } else {
        decimals = parts[1];
      }
    }
    return (
      parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, groupSeparator) +
      (!decimals ? "" : decimalSeparator + decimals)
    );
  }

  getLocalIsoDate = (date) => {
    const tzoffset = new Date(date).getTimezoneOffset() * 60000; // offset in milliseconds
    return new Date(new Date(date).getTime() - tzoffset).toISOString();
  };
}

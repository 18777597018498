import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { from, BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CoredataService } from "./coredata.service";


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService, private coredataService: CoredataService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {

    return next.handle(req).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && !this.isAuthUrl(req.url) && error.status === 401) {
        return from(this.handle401Error(req, next));
      }

      return throwError(error);
    }));
  }

  isAuthUrl(url) {
    if (url.includes('login')) return true;
    if (url.includes('logout')) return true;
    if (url.includes('refresh')) return true;
    return false;
  }

  private async handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);
      const ACCESS_TOKEN = 'access-token';
      const REFRESH_TOKEN = 'refresh-token';

      return this.authService.refreshToken().pipe(
        switchMap(async (res: any) => {
          this.refreshTokenInProgress = false;
          const auth = {
            [ACCESS_TOKEN]: res?.data?.token,
            [REFRESH_TOKEN]: res?.data?.refresh_token
          }
          await this.authService.setLocalAuth(auth);
          this.refreshTokenSubject.next(auth[ACCESS_TOKEN]);
          return next.handle(this.addTokenHeader(request, auth[ACCESS_TOKEN])).toPromise();
        }),
        catchError((err) => {
          this.refreshTokenInProgress = false;
          this.coredataService.applogout();
          return throwError(err).toPromise();
        })
      ).toPromise();
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    ).toPromise();
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token,
      },
    });
  }
}


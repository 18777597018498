import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { EnvService } from './env.service';
import { Notifications } from '../models/core';

/*
  FcmProvider provider.
*/

declare const cordova: any;

@Injectable()
export class FcmService {

  public token: string = null;
  public notifications: Notifications;
  public notificationsCount = 0;
  constructor(
    private platform: Platform,
    private firebaseNative: FirebaseX,
    private env: EnvService,
  ) {
    this.platform.ready().then(() => {
      this.env.consoleLog('FcmProvider Constructor start');
    });
  }

  /* FCM PROVIDER Get Token and permission from the user
  */
  async getToken() {
    if (!this.token) {
      // const token = await this.firebaseNative.getToken();
      if (this.platform.is('ios')) {
        this.env.consoleLog('FCM PROVIDER Push notification permission iOS');
        this.firebaseNative.hasPermission().then(async (granted) => {
          this.env.consoleLog('FCM PROVIDER has Push notification permission iOS?', granted);
          if (granted === true) {
            this.token = await this.firebaseNative.getToken();
            this.env.consoleLog('FCM PROVIDER TOKEN', this.token);
            return this.token;
           } else {
            this.firebaseNative.grantPermission().then(async (granted2) => {
              this.env.consoleLog('FCM PROVIDER has Push notification permission iOS after grant?', granted2);
              if (granted2 === true) {
                this.token = await this.firebaseNative.getToken();
              } else {
                this.token = null;
              }
              this.env.consoleLog('FCM PROVIDER TOKEN', this.token);
              return this.token;
            });
          }
        });
      } else {
        this.token = await this.firebaseNative.getToken();
        this.env.consoleLog('FCM PROVIDER TOKEN', this.token);
        return this.token;
      }
    } else {
      this.env.consoleLog('FCM PROVIDER TOKEN', this.token);
      return this.token;
    }
  }

  /* FCM PROVIDER Listen to Token Refresh
  */
  listenTokenRefresh(): Observable<any> {
    // this.env.consoleLog('listenTokenRefresh');
    return this.firebaseNative.onTokenRefresh();
  }

  /* FCM PROVIDER Update local token
  */
  updateToken(token) {
    // this.env.consoleLog('updateToken');
    this.token = token;
  }

  /* FCM PROVIDER Listen to incoming messages; User opened a notification
  */
  listenToNotifications(): Observable<any> {
    // this.env.consoleLog('FCM listenToNotifications observable');
    return this.firebaseNative.onMessageReceived();
  }

  /* FCM PROVIDER Manage Badge number
  */
  setBadgeNumber(notificationsNumber): Promise<any> {
    // this.env.consoleLog('FCM setBadgeNumber promise; notificationsNumber', notificationsNumber);
    return this.firebaseNative.setBadgeNumber(notificationsNumber);
  }

  /* FCM PROVIDER Local Notification
  */
  localNotification(notification: any) {
    cordova.plugins.notification.local.hasPermission((granted) => {
      if (granted === true) {
        this.localNotificationSend(notification);
      } else {
        cordova.plugins.notification.local.registerPermission((nowGranted) => {
          if (nowGranted === true) {
            this.localNotificationSend(notification);
          } else {
            console.error('Local notification cannot be added because app doesn\'t have permission');
          }
        });
      }
    });
  }
  localNotificationSend(notification: any) {
    cordova.plugins.notification.local.schedule({
      title: notification.title,
      text: notification.body,
      foreground: true,
      // smallIcon: 'res://notification_icon',
      color: '#FF0000',
      vibrate: true
    });
  }

}

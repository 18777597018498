import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Platform, NavController } from "@ionic/angular";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { Storage } from "@ionic/storage";
import { EnvService } from "./env.service";
import { AuthService } from "./auth.service";
import { tap, map, catchError, switchMap } from "rxjs/operators";
import { BehaviorSubject, Subject } from "rxjs";
import {
  UserData,
  AppliancesData,
  BrandsData,
  ProvincesData,
  ZipcodesData,
  DealerData,
  TicketStatusEnum,
} from "../models/core";
//import { NOTIFICATIONSMOCK, NOTIFICATIONMOCK } from './mock';

@Injectable({
  providedIn: "root",
})
export class CoredataService {
  public language = "en";

  // tslint:disable-next-line:variable-name
  private _activeTab;

  public backToBuyerForceReload$ = new Subject<string>();

  public backToSellerForceReload$ = new Subject<string>();

  public ticketDataChange$ = new BehaviorSubject<string>("start");

  public chatMessageForceReload$ = new Subject<string>();

  public notificationCountChange$ = new Subject<number>();

  public userUpdateDataExchange: UserData;

  public unopenedPosts = 0;

  public role = { vendor: true, technician: false };

  public appliancesData: AppliancesData = { appliances: [] };
  public brandsData: BrandsData = { brands: [] };
  public provincesData: ProvincesData = { provinces: [] };
  public zipcodesData: ZipcodesData = { zipcodes: [] };
  public dealerDetail: DealerData = null;
  public userPreferencesData: any = null;
  public userPreferencesFilters = {
    brands: [],
    appliances: [],
    provinces: [],
    order: "desc",
  };
  public userPreferencesDescriptions = {
    brands: [],
    appliances: [],
    provinces: [],
    order: "desc",
  };
  public buyerTicketsFilters = {
    brands: [],
    appliances: [],
    provinces: [],
    order: "desc",
    ready: false,
  };
  buyerSetTicketsFilters = false;

  public sellerTicketsFilters = {
    brands: [],
    appliances: [],
    provinces: [],
    order: "desc",
    ready: false,
  };
  sellerSetTicketsFilters = false

  public dealersFilters = {
    brands: [],
    appliances: [],
    provinces: [],
  };
  setDealersFilters = false;

  public transactionsFilters = {
    user: null,
    province: null,
    typology: null,
    start: '',
    end: ''
  }
  setTransactionsFilters = false;

  public transactionTicketsFilters = {
    start: '',
    end: ''
  }

  setTransactionTicketsFilters = false;

  public premiumSellerIds = [];
  public premiumBuyerIds = [];
  public notificationCount = 0;

  /* BROWSER */
  isMobile = true;
  isBrowser = false;

  /* MENU */
  selectedMenu = 'buyer';
  selectedItem = '/buyer/data';

  constructor(
    private platform: Platform,
    private http: HttpClient,
    private navCtrl: NavController,
    private storage: Storage,
    private env: EnvService,
    private authService: AuthService
  ) {
    this.env.consoleLog("CoredataService Constructor START");
    this.platform.ready().then(async () => {
      if (
        (this.platform.is('ios')
          || this.platform.is('android'))
        && (!(this.platform.is('desktop') || this.platform.is('mobileweb')))) {
        this.isMobile = true;
        this.isBrowser = false;
      } else {
        this.isMobile = false;
        this.isBrowser = true;
      }
      this.language = this.env.language;
    });
  }

  get activeTab() {
    return this._activeTab;
  }
  set activeTab(value: string) {
    this._activeTab = value;
  }

  getBuyerForceReloadObservable(): Observable<string> {
    return this.backToBuyerForceReload$.asObservable();
  }

  getSellerForceReloadObservable(): Observable<string> {
    return this.backToSellerForceReload$.asObservable();
  }

  getChatMessageForceReloadObservable(): Observable<string> {
    return this.chatMessageForceReload$.asObservable();
  }

  getNotificationCountChangeObservable(): Observable<number> {
    return this.notificationCountChange$.asObservable();
  }


  reloadBuyer(msg = 'reload') {
    this.backToBuyerForceReload$.next(msg);
  }

  reloadSeller() {
    this.backToSellerForceReload$.next("reload");
  }

  reloadChatMessage() {
    this.chatMessageForceReload$.next("reload");
  }

  ticketDataChange() {
    this.ticketDataChange$.next("reload");
  }

  /* Local Storage: Get from Storage data
   */
  async getStorageData(datakey): Promise<any> {
    return await this.storage.get(`${this.env.PRJKEY}#${datakey}`);
  }

  /* Local Storage: Set Storage with data
   */
  async setStorageData(datakey, value): Promise<any> {
    return await this.storage.set(`${this.env.PRJKEY}#${datakey}`, value);
  }

  getAttributesData() {
    this.getUserPreferencesData();
    this.getAppliancesData();
    this.getBrandsData();
    this.getProvincesData();
    this.getZipcodesData();
  }

  getUserPreferencesData() {
    this.getUserPreferences().subscribe(data => {
      this.env.consoleLog('USER PREFERENCES (BE)', data)

      if (data?.data) {

        /* Prepare Normalized Preferences */
        // this.userPreferencesData = (new UserData(data.data.user));
        this.userPreferencesData = (new UserData(data.data[0]));
        this.authService.setLocalUser(this.userPreferencesData);

        /* This to break elab asyncronous */
        this.userPreferencesFilters.appliances = [];
        this.userPreferencesDescriptions.appliances = [];
        // this.buyerTicketsFilters.appliances = [];
        for (const appliance of this.userPreferencesData.appliances) {
          const { id, name } = appliance;
          this.userPreferencesFilters.appliances.push({ id, name });
          // if (mode === 'start') {
          // this.buyerTicketsFilters.appliances.push({ id, name });
          this.userPreferencesDescriptions.appliances.push(appliance.name);
          // }

          if (!this.buyerTicketsFilters.ready) {
            this.buyerTicketsFilters.appliances.push({ id, name });
          }
        }

        /* This to break elab asyncronous */
        this.userPreferencesFilters.brands = []
        this.userPreferencesDescriptions.brands = [];
        // this.buyerTicketsFilters.brands = [];
        for (const brand of this.userPreferencesData.brands) {
          const { id, name } = brand;
          this.userPreferencesFilters.brands.push({ id, name });
          // if (mode === 'start') {
          // this.buyerTicketsFilters.brands.push({ id, name });
          this.userPreferencesDescriptions.brands.push(brand.name);
          // }
          if (!this.buyerTicketsFilters.ready) {
            this.buyerTicketsFilters.brands.push({ id, name });
          }
        }

        /* This to break elab asyncronous */
        this.userPreferencesFilters.provinces = []
        this.userPreferencesDescriptions.provinces = []
        // this.buyerTicketsFilters.provinces = [];
        for (const province of this.userPreferencesData.provinces) {
          const { id, name } = province;
          this.userPreferencesFilters.provinces.push({ id, name });
          // if (mode === 'start') {
          // this.buyerTicketsFilters.provinces.push({ id, name });
          this.userPreferencesDescriptions.provinces.push(province.name);
          // }
          if (!this.buyerTicketsFilters.ready) {
            this.buyerTicketsFilters.provinces.push({ id, name });
          }
        }


      }

      this.buyerTicketsFilters.ready = true;
      this.sellerTicketsFilters.ready = true;

      this.env.consoleLog(
        "USER PREFERENCES (NORMALIZED)",
        this.userPreferencesData
      );
      this.env.consoleLog(
        "USER PREFERENCES FILTERS",
        this.userPreferencesFilters
      );
      this.env.consoleLog(
        "USER PREFERENCES DESCRIPTIONS",
        this.userPreferencesDescriptions
      );
      this.env.consoleLog("BUYER FILTERS", this.buyerTicketsFilters);
      this.env.consoleLog("SELLER FILTERS", this.sellerTicketsFilters);
    },
      (error) => {
        this.env.consoleLog("CoredataService.getUserPreferences error", error);
        this.buyerTicketsFilters.ready = true;
        this.sellerTicketsFilters.ready = true;
      }
    );
  }

  getAppliancesData() {
    this.getAppliances().subscribe(
      (data) => {
        this.env.consoleLog("APPLIANCES (BE)", data);

        if (data?.data) {
          /* Prepare Normalized Appliances */
          this.appliancesData = new AppliancesData(data.data);
        }
        this.env.consoleLog("APPLIANCES (NORMALIZED)", this.appliancesData);
      },
      (error) => {
        this.env.consoleLog("CoredataService.getAppliancesData error", error);
      }
    );
  }

  getBrandsData() {
    this.getBrands().subscribe(
      (data) => {
        this.env.consoleLog("BRANDS (BE)", data);

        if (data?.data) {
          /* Prepare Normalized Brands */
          this.brandsData = new BrandsData(data.data);
        }
        this.env.consoleLog("BRANDS (NORMALIZED)", this.brandsData);
      },
      (error) => {
        this.env.consoleLog("CoredataService.getBrandsData error", error);
      }
    );
  }

  getProvincesData() {
    this.getProvinces().subscribe(
      (data) => {
        this.env.consoleLog("PROVINCES (BE)", data);

        if (data?.data) {
          /* Prepare Normalized Provinces */
          this.provincesData = new ProvincesData(data.data);
        }
        this.env.consoleLog("PROVINCES (NORMALIZED)", this.provincesData);
      },
      (error) => {
        this.env.consoleLog("CoredataService.getProvincesData error", error);
      }
    );
  }

  getZipcodesData() {
    this.getZipcodes().subscribe(
      (data) => {
        this.env.consoleLog("ZIPCODES (BE)", data);

        if (data?.data) {
          /* Prepare Normalized Zipcodes */
          this.zipcodesData = new ZipcodesData(data.data);
        }
        this.env.consoleLog("ZIPCODES (NORMALIZED)", this.zipcodesData);
      },
      (error) => {
        this.env.consoleLog("CoredataService.getZipcodesData error", error);
      }
    );
  }

  /* API Call: Get from Backend User Preferences List data
   */
  getUserPreferences(): Observable<any> {
    return this.getData(`user-preferences?locale=${this.language}`);
  }

  /* API Call: Get from Backend Brands List data
   */
  getBrands(): Observable<any> {
    return this.getData(`brands?locale=${this.language}`);
  }

  /* API Call: Get from Backend Appliances List data
   */
  getAppliances(): Observable<any> {
    return this.getData(`appliances?locale=${this.language}`);
  }

  /* API Call: Put Backend with new user preferences Brands data
   */
  putUserBrands(userId, body): Observable<any> {
    return this.putData(`user-brand/${userId}?locale=${this.language}`, body);
  }

  /* API Call: Put Backend with new user preferences Appliances data
   */
  putUserAppliances(userId, body): Observable<any> {
    return this.putData(
      `user-appliance/${userId}?locale=${this.language}`,
      body
    );
  }

  /* API Call: Put Backend with new user preferences Provinces data
  */
  putUserProvinces(userId, body): Observable<any> {
    return this.putData(
      `user-province/${userId}?locale=${this.language}`,
      body
    );
  }

  /* API Call: Get from Backend Provinces List data
   */
  getProvinces(): Observable<any> {
    return this.postData(`provinces?locale=${this.language}`, {});
  }

  /* API Call: Get from Backend Provinces List data
   */
  getZipcodes(): Observable<any> {
    return this.postData(`zipcodes?locale=${this.language}`, {});
  }

  /* API Call: Get from Backend BUYER DASHBOARD Tickets List data
   */
  getTicketsBuyer(body, page: number, rows: number): Observable<any> {
    if (page && page > 0 && rows && rows > 0) {
      return this.postData(
        `display-ticket?page=${page}&rows=${rows}&locale=${this.language}`,
        body
      );
    } else {
      return this.postData(`display-ticket?locale=${this.language}`, body);
    }
  }

  /* API Call: Get from Backend SELLER DASHBOARD Tickets List data
   */
  getTicketsSeller(body, page: number, rows: number): Observable<any> {
    if (page && page > 0 && rows && rows > 0) {
      return this.postData(
        `seller-board?page=${page}&rows=${rows}&locale=${this.language}`,
        body
      );
    } else {
      return this.postData(`seller-board?locale=${this.language}`, body);
    }
  }

  /* API Call: Get from Backend Tickets Detail data
   */
  getTicketDetail(ticketId): Observable<any> {
    return this.getData(`tickets/${ticketId}?locale=${this.language}`);
  }

  /* API Call: Get fee for both buyer and seller
   */
  getTicketFee(): Observable<any> {
    return this.getData(`settings`);
  }

  /* API Call: Get from Backend put Tickets as BOOKED
   */
  buyTicket(userId, ticketId): Observable<any> {
    return this.postData(
      `book-ticket?user_id=${userId}&ticket_id=${ticketId}&locale=${this.language}`,
      {}
    );
  }

  /* API Call: Get from Backend put Tickets as BOOKED
   */
  createNewTicket(body): Observable<any> {
    return this.postData(`tickets?locale=${this.language}`, body);
  }

  updateTicket(ticketId, body): Observable<any> {
    return this.putData(`tickets/${ticketId}`, body);
  }

  updateTicketStatus(body): Observable<any> {
    return this.postData(`update-ticket-status`, body);
  }

  getBookableTickets(): Observable<any> {
    return this.getData(`user-ticket-limit?locale=${this.language}`);
  }

  /* API Call: Set from Backend Not Interested Reasons data
   */
  setNotInterestedReasons(body): Observable<any> {
    return this.postData(`hide-tickets?locale=${this.language}&${body}`, null);
  }

  getNotInterestedBrandsAndAppliances(): Observable<any> {
    return this.getData(`buyer_exclusions?locale=${this.language}`);
  }

  /* API Call: Get from Backend Cancel Buy Reasons List data
   */
  getCancelBuyReasons(): Observable<any> {
    return this.getData(`list-cancel-reason?locale=${this.language}`);
  }

  /* API Call: Set from Backend Cancel Buy Ticket data
   */
  setCancelBuy(body): Observable<any> {
    return this.postData(`cancel-buy?locale=${this.language}&${body}`, {});
  }

  getChatThread(body): Observable<any> {
    return this.postData(`get-ticket-thread?locale=${this.language}`, body);
  }

  replyChat(body): Observable<any> {
    return this.postData(`chats?locale=${this.language}`, body);
  }

  getChatList(page, rows): Observable<any> {
    if (page && page > 0 && rows && rows > 0) {
      return this.getData(
        `chat-thread?page=${page}&rows=${rows}&locale=${this.language}`
      );
    } else {
      return this.getData(`chat-thread?locale=${this.language}`);
    }
  }

  getInAppNotifications(page, rows): Observable<any> {
    if (page && page > 0 && rows && rows > 0) {
      return this.getData(
        `notifications?page=${page}&rows=${rows}&locale=${this.language}`
      );
    } else {
      return this.getData(`notifications?locale=${this.language}`);
    }
  }

  /* API Call: Get from Backend Dealers List data
   */
  getDealers(userId): Observable<any> {
    return this.getData(`list-dealer?locale=${this.language}`);
  }

  getFilteredDealerList(body): Observable<any> {
    return this.postData(`filter-dealer?locale=${this.language}`, body);
  }

  /* API Call: Get from Backend Dealer Detail data
   */
  getDealerDetail(userId): Observable<any> {
    return this.getData(`dealers/${this.dealerDetail}?locale=${this.language}`);
  }

  /* API Call: Get from Backend User data
   */
  getUser(userId): Observable<any> {
    return this.getData(`user-profile?locale=${this.language}`);
  }

  /* API Call: Post Update User Avatar to Backen
   */
  updateUserAvatar(body): Observable<any> {
    return this.postData(`user-avatar?locale=${this.language}`, body);
  }

  updateProfile(id, body): Observable<any> {
    return this.postData(`update-user/${id}`, body);
  }

  deleteUser(userId): Observable<any> {
    return this.deleteData(`delete-user/${userId}`);
  }

  contactUs(body): Observable<any> {
    return this.postData(`contact-us`, body);
  }

  getTransactionHistory(body, page, rows): Observable<any> {
    if (page && page > 0 && rows && rows > 0) {
      return this.postData(
        `user-transaction?page=${page}&rows=${rows}&locale=${this.language}`,
        body
      );
    } else {
      return this.postData(`user-transaction?locale=${this.language}`, body);
    }
  }

  getTransactionHistoryDetail(body): Observable<any> {
    return this.postData(`user-transaction-detail?locale=${this.language}`, body);
  }

  getTransactionUsers(): Observable<any> {
    return this.getData(`user-transaction/user-list?locale=${this.language}`);
  }

  getTicketHistoryStats(query): Observable<any> {
    return this.getData(`get-stats?locale=${this.language}&type=${query.type}`);
  }

  getTicketHistory(ticketId): Observable<any> {
    return this.getData(`ticket-history/${ticketId}`);
  }

  getCancellableDateTime(ticketId): Observable<any> {
    return this.getData(`get-cancellable-time`);
  }

  getMunicipalities(zipCode): Observable<any> {
    return this.getData(`get-municipalities/${zipCode}`);
  }


  /* ===============================================================
     BACKUP
  */

  /* API Call: Set FCM Token
   */
  setFcmToken(userId, body): Observable<any> {
    return this.postData(
      `user/${userId}/set_fcm_token?locale=${this.language}`,
      body
    );
  }

  patchUser(userId, body): Observable<any> {
    return this.patchData(`app_users/${userId}`, body);
  }

  /* API Call: Get from Backend Notification List data
   */
  getNotifications(userId): Observable<any> {
    return this.getData(
      `users/${userId}/communications?locale=${this.language}`
    );
  }

  /* API Call: Get from Backend Notification data
   */
  getNotification(notificationUuid): Observable<any> {
    return this.getData(
      `communications/${notificationUuid}?locale=${this.language}`
    );
  }

  /* API Call: Update on Backend Notification Status data to open
   */
  updateNotificationStatus(notificationUuid): Observable<any> {
    return this.postData(
      `communications/${notificationUuid}/seen?locale=${this.language}`,
      {}
    );
  }

  getUnreadNotificationsCount() {
    return this.getData(
      `unread-notifications-count?locale=${this.language}`
    );
  }

  getNotificationsCount() {
    this.getUnreadNotificationsCount().subscribe((data) => {
      console.log('getUnreadNotificationsCount', data)
      if (data) {
        this.notificationCount = data;
        this.notificationCountChange$.next(data)
      }
    })
  }

  closeNotificationsCount() {
    this.notificationCount = 0;
    this.notificationCountChange$.next(0);
    this.markNotificationRead().subscribe();
  }

  markNotificationRead() {
    return this.postData(`mark-notification-read`, null);
  }

  updateFCMToken(payload): Observable<any> {
    return this.postData(
      `update-fcm-token`,
      payload
    );
  }


  getPremiumSeller() {
    return this.getData(
      `get-premium-seller?locale=${this.language}`
    );
  }

  getPremiumBuyer() {
    return this.getData(
      `get-premium-buyer?locale=${this.language}`
    );
  }

  checkAppVersion(payload) {
    return this.postData(
      `check-app-version`,
      payload
    );
  }

  getRechargePackages() {
    return this.getData(
      `recharge-packages?locale=${this.language}`
    );
  }

  buyPackage(payload) {
    return this.postData(
      `recharge-packages/buy`,
      payload
    );
  }

  creditCardTransferSuccess(payload) {
    return this.postData(
      `payment-successful`,
      payload
    );
  }

  redeemCoin(payload) {
    return this.postData(
      `redeem-coin`,
      payload
    );
  }

  getExchangeRate() {
    return this.getData(`exchange-rate`);
  }

  cancelStripePayment(payload) {
    return this.postData(
      `payment-cancelled`,
      payload
    );
  }

  /* ===============================================================
     COMMON METHODS
  */

  /* General API Call: Get data from Backend
   */
  getData(apiPath): Observable<any> {
    const apiMethod = `${this.env.API_URL}${apiPath}`;
    let headers = null;
    if (this.authService?.auth && this.authService?.auth["access-token"]) {
      headers = {
        "Content-Type": "application/json",
        Authorization:
          this.authService?.auth && this.authService?.auth["access-token"]
            ? "Bearer " + this.authService?.auth["access-token"]
            : null,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }
    return this.http
      .get(apiMethod, {
        headers: headers,
        observe: "response",
      })
      .pipe(
        catchError((err) => {
          if (err && err.status === 401) {
            // this.logout();
            return throwError(err);
            //return this.forRefreshForGetDataDo(apiMethod);
          } else {
            return throwError(err);
          }
        }),
        map((res: any) => {
          return res.body;
        })
      );
  }

  forRefreshForGetDataDo(apiMethod): Observable<any> {
    return this.authService.validateToken().pipe(
      catchError((err) => {
        if (err && err.status === 401) {
          // this.logout();
          return err;
        } else {
          return err;
        }
      }),
      tap(async (response: any) => {
        await this.authService.setAuthAfterValidation(response.auth);
      }),
      switchMap(() => {
        return this.http.get(apiMethod, {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              this.authService?.auth && this.authService?.auth["access-token"]
                ? "Bearer " + this.authService?.auth["access-token"]
                : null,
          },
          observe: "response",
        });
      }),
      map((res) => res)
    );
  }

  /* General API Call: Post data to Backend
   */
  postData(apiPath, body): Observable<any> {
    const apiMethod = `${this.env.API_URL}${apiPath}`;
    let headers = null;
    if (this.authService?.auth && this.authService?.auth["access-token"]) {
      headers = {
        "Content-Type": "application/json",
        Authorization:
          this.authService?.auth && this.authService?.auth["access-token"]
            ? "Bearer " + this.authService?.auth["access-token"]
            : null,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }
    return this.http
      .post<any>(apiMethod, JSON.stringify(body), {
        headers: headers,
        observe: "response",
      })
      .pipe(
        catchError((err) => {
          if (err && err.status === 401) {
            // this.logout();
            return throwError(err);
            //return this.forRefreshForPostDataDo(apiMethod, body);
          } else {
            return throwError(err);
          }
        }),
        map((res: any) => {
          return res.body;
        })
      );
  }

  forRefreshForPostDataDo(apiMethod, body): Observable<any> {
    return this.authService.validateToken().pipe(
      catchError((err) => {
        if (err && err.status === 401) {
          // this.logout();
          return err;
        } else {
          return err;
        }
      }),
      tap(async (response: any) => {
        await this.authService.setAuthAfterValidation(response.auth);
      }),
      switchMap(() => {
        return this.http.post(apiMethod, JSON.stringify(body), {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              this.authService?.auth && this.authService?.auth["access-token"]
                ? "Bearer " + this.authService?.auth["access-token"]
                : null,
          },
          observe: "response",
        });
      }),
      map((res) => res)
    );
  }

  /* General API Call: Put data to Backend
   */
  putData(apiPath, body): Observable<any> {
    const apiMethod = `${this.env.API_URL}${apiPath}`;
    let headers = null;
    if (this.authService?.auth && this.authService?.auth["access-token"]) {
      headers = {
        "Content-Type": "application/json",
        Authorization:
          this.authService?.auth && this.authService?.auth["access-token"]
            ? "Bearer " + this.authService?.auth["access-token"]
            : null,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }
    return this.http
      .put<any>(apiMethod, JSON.stringify(body), {
        headers: headers,
        observe: "response",
      })
      .pipe(
        catchError((err) => {
          if (err && err.status === 401) {
            // this.logout();
            return throwError(err);
            //return this.forRefreshForPutDataDo(apiMethod, body);
          } else {
            return throwError(err);
          }
        }),
        map((res: any) => {
          return res.body;
        })
      );
  }

  forRefreshForPutDataDo(apiMethod, body): Observable<any> {
    return this.authService.validateToken().pipe(
      catchError((err) => {
        if (err && err.status === 401) {
          // this.logout();
          return err;
        } else {
          return err;
        }
      }),
      tap(async (response: any) => {
        await this.authService.setAuthAfterValidation(response.auth);
      }),
      switchMap(() => {
        return this.http.put(apiMethod, JSON.stringify(body), {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              this.authService?.auth && this.authService?.auth["access-token"]
                ? "Bearer " + this.authService?.auth["access-token"]
                : null,
          },
          observe: "response",
        });
      }),
      map((res) => res)
    );
  }

  /* General API Call: Put data to Backend
   */
  patchData(apiPath, body): Observable<any> {
    const apiMethod = `${this.env.API_URL}${apiPath}`;
    let headers = null;
    if (this.authService?.auth && this.authService?.auth["access-token"]) {
      headers = {
        "Content-Type": "application/json",
        Authorization:
          this.authService?.auth && this.authService?.auth["access-token"]
            ? "Bearer " + this.authService?.auth["access-token"]
            : null,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }
    return this.http
      .patch<any>(apiMethod, JSON.stringify(body), {
        headers: headers,
        observe: "response",
      })
      .pipe(
        catchError((err) => {
          if (err && err.status === 401) {
            // this.logout();
            return throwError(err);
            //return this.forRefreshForPatchDataDo(apiMethod, body);
          } else {
            return throwError(err);
          }
        }),
        map((res: any) => {
          return res.body;
        })
      );
  }

  forRefreshForPatchDataDo(apiMethod, body): Observable<any> {
    return this.authService.validateToken().pipe(
      catchError((err) => {
        if (err && err.status === 401) {
          // this.logout();
          return err;
        } else {
          return err;
        }
      }),
      tap(async (response: any) => {
        await this.authService.setAuthAfterValidation(response.auth);
      }),
      switchMap(() => {
        return this.http.patch(apiMethod, JSON.stringify(body), {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              this.authService?.auth && this.authService?.auth["access-token"]
                ? "Bearer " + this.authService?.auth["access-token"]
                : null,
          },
          observe: "response",
        });
      }),
      map((res) => res)
    );
  }

  /* General API Call: Delete data to Backend
   */
  deleteData(apiPath): Observable<any> {
    const apiMethod = `${this.env.API_URL}${apiPath}`;
    let headers = null;
    if (this.authService?.auth && this.authService?.auth["access-token"]) {
      headers = {
        "Content-Type": "application/json",
        Authorization:
          this.authService?.auth && this.authService?.auth["access-token"]
            ? "Bearer " + this.authService?.auth["access-token"]
            : null,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }
    return this.http
      .delete<any>(apiMethod, {
        headers: headers,
        observe: "response",
      })
      .pipe(
        catchError((err) => {
          if (err && err.status === 401) {
            // this.logout();
            return throwError(err);
            //return this.forRefreshForDeleteDataDo(apiMethod);
          } else {
            return throwError(err);
          }
        }),
        map((res: any) => {
          return res.body;
        })
      );
  }

  forRefreshForDeleteDataDo(apiMethod): Observable<any> {
    return this.authService.validateToken().pipe(
      catchError((err) => {
        if (err && err.status === 401) {
          // this.logout();
          return err;
        } else {
          return err;
        }
      }),
      tap(async (response: any) => {
        await this.authService.setAuthAfterValidation(response.auth);
      }),
      switchMap(() => {
        return this.http.delete(apiMethod, {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              this.authService?.auth && this.authService?.auth["access-token"]
                ? "Bearer " + this.authService?.auth["access-token"]
                : null,
          },
          observe: "response",
        });
      }),
      map((res) => res)
    );
  }


  /* LOGOUT
  */
  logout() {
    // this.env.consoleLog('AppComponent logout1');
    this.authService.logout().subscribe(() => {
      this.applogout();
      // this.invitationNum = 0;
    }, error => {
      this.applogout();
    });
  }

  applogout() {
    this.env.consoleLog('AppComponent logout2');
    this.navCtrl.navigateRoot('/entrance');
    this.authService.removeLocalUser();
    this.authService.removeLocalAuth();
    this.clear();
  }

  /* This method is required since this service is injected 
    at root level. So the single shared instance will persist 
    data. Problem: If user 1 logs out and user 2 logs in.
    Initially user 1 data will be used for user 2. */

  clear() {
    this.userUpdateDataExchange = null
    this.unopenedPosts = 0;
    this.role = { vendor: true, technician: false }
    this.appliancesData = { appliances: [] };
    this.brandsData = { brands: [] };
    this.provincesData = { provinces: [] };
    this.zipcodesData = { zipcodes: [] };
    this.dealerDetail = null;
    this.userPreferencesData = null;
    this.userPreferencesFilters = {
      brands: [],
      appliances: [],
      provinces: [],
      order: 'desc'
    };
    this.userPreferencesDescriptions = {
      brands: [],
      appliances: [],
      provinces: [],
      order: 'desc'
    };
    this.buyerTicketsFilters = {
      brands: [],
      appliances: [],
      provinces: [],
      order: 'desc',
      ready: false
    };
    this.buyerSetTicketsFilters = false

    this.sellerTicketsFilters = {
      brands: [],
      appliances: [],
      provinces: [],
      order: 'desc',
      ready: false
    };
    this.sellerSetTicketsFilters = false
    this.dealersFilters = {
      brands: [],
      appliances: [],
      provinces: [],
    };
    this.setDealersFilters = false

    this.transactionsFilters = {
      user: null,
      province: null,
      typology: null,
      start: '',
      end: ''
    }
    this.setTransactionsFilters = false;

    this.transactionTicketsFilters = {
      start: '',
      end: ''
    }

    this.setTransactionTicketsFilters = false;

    this.notificationCount = 0;

  }

}

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { AppVersion } from '@ionic-native/app-version/ngx';

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { IonicStorageModule } from "@ionic/storage";
import { Network } from "@ionic-native/network/ngx";
import { Device } from "@ionic-native/device/ngx";
import { BuildInfo } from "@ionic-native/build-info/ngx";
import { File } from "@ionic-native/file/ngx";
import { Camera } from "@ionic-native/camera/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { ImageCropperModule } from "ngx-image-cropper";

import { FcmService } from "src/app/services/fcm.service";

import { TranslateModule } from "../app/translate/translate.module";
import { JwtInterceptor } from "./services/jwt-intercpetor.service";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot({
      name: "__wfmae",
      driverOrder: ["sqlite", "localstorage"],
    }),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ImageCropperModule,
    ScrollingModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Keyboard,
    Network,
    Device,
    BuildInfo,
    InAppBrowser,
    File,
    Camera,
    AppVersion,
    AndroidPermissions,
    Diagnostic,
    FirebaseX,
    FcmService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule { }
